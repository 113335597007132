<template>

  <div class="w1092">
    <div class="mybetwrap">
      <ul class="tabs">
        <li :class="{'current': faqType == 'all'}" @click="onSelectType('all')">
          <em class="qnaicon1"></em>
          <span>{{$t('front.gameCategory.all')}}</span>
        </li>
        <li :class="{'current': faqType == 'casino'}" @click="onSelectType('casino')">
          <em class="qnaicon2"></em>
          <span>{{$t('front.gameCategory.livecasino')}}</span>
        </li>
        <li :class="{'current': faqType == 'slot'}" @click="onSelectType('slot')">
          <em class="qnaicon3"></em>
          <span>{{$t('front.gameCategory.slot')}}</span>
        </li>
        <!--li>
          <em class="qnaicon4"></em>
          <span>스포츠</span>
        </li>
        <li>
          <em class="qnaicon5"></em>
          <span>E-스포츠</span>
        </li>
        <li>
          <em class="qnaicon6"></em>
          <span>미니게임</span>
        </li-->
        <li :class="{'current': faqType == 'cash'}" @click="onSelectType('cash')">
          <em class="qnaicon7"></em>
          <span>{{$t('front.gameCategory.cash')}}</span>
        </li>
        <li :class="{'current': faqType == 'betInfo'}" @click="onSelectType('betInfo')">
          <em class="qnaicon8"></em>
          <span>{{$t('front.gameCategory.betting')}}</span>
        </li>
        <li :class="{'current': faqType == 'etc'}" @click="onSelectType('etc')">
          <em class="qnaicon9"></em>
          <span>{{$t('front.gameCategory.etc')}}</span>
        </li>
      </ul>
    </div><!-- mybetwrap -->
    <ul class="qnawrap">
      <template v-for="(item, index) in list">
        <li v-if="item && (item.category == faqType || faqType == 'all')" v-bind:key="index + '_' + item.title">
          <h4 class="qnahead">
            <span class="nbicon rdbg" v-if="item.codeName">{{ item.codeName }}</span>
            {{ item.title }}
          </h4>
          <div class="qnacon">
            <div class="qnaconin">
              <p v-html="item.content">
              </p>
            </div>
          </div>
        </li>
      </template>

    </ul>

  </div>

</template>

<script>

export default {
  name: 'faqList',
  components: {},
  data () {
    return {
      list: null,
      boardType: 'favor',
      faqType: 'all'
    }
  },
  created () {
    this.loadList()
  },
  methods: {
    onSelectType (value) {
      this.faqType = value
      this.loadList()
    },
    onSearch (value) {
      this.loadList()
    },
    async loadList () {
      const params = {
        boardType: this.boardType
      }

      if (this.faqType !== 'all') {
        params.faqType = this.faqType
      } else {
        if (params.faqType) {
          delete params.faqType
        }
      }

      console.log(params, this.categoryCodeByCode)

      const result = await this.getBoardList(params, this.pageInfo.page)
      this.list = result.list
      this.list.forEach(item => {
        item.content = item.content.replace(/\n/g, '<br>')
        item.isOpen = true
        item.codeName = item.category ? (this.categoryCodeByCode.favor[item.category] ? this.categoryCodeByCode.favor[item.category].codeName : '기타') : '기타'
      })
    },
    onClickRead (id) {
      if (!id) id = 1
      this.$router.push({ name: 'qnaRead', params: { id: id } })
    },
    onClickWrite () {
      this.$router.push({ name: 'qnaWrite' })
    }
  }
}
</script>
<style scoped>
/*.qnaicon1 {background: url("~@/assets/img/all_off.png") no-repeat;}
.qnaicon2 {background: url("~@/assets/img/livecasino_off.png") no-repeat;}
.qnaicon3 {background: url("~@/assets/img/slot_off.png") no-repeat;}
.qnaicon4 {background: url("~@/assets/img/sports_off.png") no-repeat;}
.qnaicon5 {background: url("~@/assets/img/e-sports_off.png") no-repeat;}
.qnaicon6 {background: url("~@/assets/img/minigame_off.png") no-repeat;}
.qnaicon7 {background: url("~@/assets/img/exchange_off.png") no-repeat;}
.qnaicon8 {background: url("~@/assets/img/betting_off.png") no-repeat;}
.qnaicon9 {background: url("~@/assets/img/etc_off.png") no-repeat;}
li:hover .qnaicon1, .current .qnaicon1 {background: url("~@/assets/img/all_on.png") no-repeat;}
li:hover .qnaicon2, .current .qnaicon2 {background: url("~@/assets/img/livecasino_on.png") no-repeat;}
li:hover .qnaicon3, .current .qnaicon3 {background: url("~@/assets/img/slot_on.png") no-repeat;}
li:hover .qnaicon4, .current .qnaicon4 {background: url("~@/assets/img/sports_on.png") no-repeat;}
li:hover .qnaicon5, .current .qnaicon5 {background: url("~@/assets/img/esports_on.png") no-repeat;}
li:hover .qnaicon6, .current .qnaicon6 {background: url("~@/assets/img/minigame_on.png") no-repeat;}
li:hover .qnaicon7, .current .qnaicon7 {background: url("~@/assets/img/exchange_on.png") no-repeat;}
li:hover .qnaicon8, .current .qnaicon8 {background: url("~@/assets/img/betting_on.png") no-repeat;}
li:hover .qnaicon9, .current .qnaicon9 {background: url("~@/assets/img/etc_on.png") no-repeat;}*/
.mybetwrap .tabs {margin-bottom: 18px !important;}
.mybetwrap .tabs li {display: flex;justify-content: center;align-items: center;gap: 6px;}
.mybetwrap .tabs li em {width: 29px;height: 25px;display: none;}
.mybetwrap .tabs li span {font-size: 13px;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
